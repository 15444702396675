<template>
    <div class="align-items-start p-3 text-start">
        <nav aria-label="breadcrumb">
        <ol class="breadcrumb text-dark m-auto">
            <li class="breadcrumb-item active" aria-current="page">店家管理</li>
            <li class="breadcrumb-item active" aria-current="page">好康優惠</li>
            <li class="breadcrumb-item active" aria-current="page">好康優惠編輯</li>
        </ol>
        </nav>
        <hr class="w-100">
        <Form @submit="save()" :validation-schema="schema" @invalid-submit="on_invalid_submit">
            <div class="col-12 row mx-auto data-form">
                <div class="mb-3">
                    <label for="coupon_id" class="form-label">序號</label>
                    <input type="text" class="form-control" id="coupon_id" :disabled="true" v-model="coupon_data.coupon_id">
                </div>
                <div v-if="$route.params.id === undefined">
                    <label class="form-label">帶入店家資料</label>
                </div>
                <div class="mb-3" v-if="$route.params.id === undefined">
                    <button class="btn m-2 btn-dark" data-bs-toggle="modal" data-bs-target="#staticBackdropSearch" @click.prevent="search_store_data()">選擇</button>
                </div>
                <div class="mb-3">
                    <label for="store_code" class="form-label">店家編號</label>
                    <Field name="store_code" v-slot="{ meta, field }" v-model="coupon_data.store_code">
                        <input
                            id="store_code"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="true"
                        />
                    </Field>
                    <ErrorMessage name="store_code" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="store_name" class="form-label">店家名稱</label>
                    <input type="text" class="form-control" id="store_name" v-model="coupon_data.store_name" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="store_type" class="form-label">店家類型</label>
                    <select id="store_type" class="form-select" v-model="coupon_data.store_type" :disabled="true">
                        <option value="" :disabled="true">請選擇</option>
                        <template v-for="item in store_type_option" :key="item">
                            <option :value="item.value">{{ item.text }}</option>
                        </template>
                    </select>
                </div>
                <div class="mb-3">
                    <label for="address" class="form-label">店家地址</label>
                    <input type="text" class="form-control" id="address" v-model="coupon_data.address" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="phone" class="form-label">店家電話</label>
                    <input type="text" class="form-control" id="phone" v-model="coupon_data.phone" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="web_site" class="form-label">網站連結</label>
                    <input type="text" class="form-control" id="web_site" v-model="coupon_data.web_site" :disabled="true">
                </div>
                <div class="mb-3">
                    <label for="introduce" class="form-label">店家簡介</label>
                    <textarea class="form-control" id="introduce" rows="8" v-model="coupon_data.introduce" :disabled="true"></textarea>
                </div>
                <div class="mb-3">
                    <label class="form-label">店家啟用狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="store_status_options" id="store_status_radio_1" :value="1" v-model="coupon_data.store_status" :disabled="true" :checked="coupon_data.store_status === 1">
                            <label class="form-check-label" for="store_status_radio_1">啟用</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="store_status_options" id="store_status_radio_1" :value="0" v-model="coupon_data.store_status" :disabled="true" :checked="coupon_data.store_status === 0">
                            <label class="form-check-label" for="store_status_radio_2">停用</label>
                        </div>
                    </div>
                </div>
                <div class="mb-3">
                    <label for="promotion_item" class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>優惠商品名稱</label>
                    <Field name="promotion_item" v-slot="{ meta, field }" v-model="coupon_data.promotion_item">
                        <input
                            id="promotion_item"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'CouponView'"
                        />
                    </Field>
                    <ErrorMessage name="promotion_item" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="promotion_content" class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>優惠折扣</label>
                    <Field name="promotion_content" v-slot="{ meta, field }" v-model="coupon_data.promotion_content">
                        <input
                            id="promotion_content"
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            :disabled="$route.name === 'CouponView'"
                        />
                    </Field>
                    <ErrorMessage name="promotion_content" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label for="start_date" class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>優惠期間</label>
                    <div>
                        <span class="d-flex">
                            <Field name="start_date" v-slot="{ meta, field }" v-model="coupon_data.start_date">
                                <input
                                    type="date"
                                    id="start_date"
                                    v-bind="field"
                                    class="form-control w-auto"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    min="2022-06-01"
                                    max="9999-12-31"
                                    :disabled="$route.name === 'CouponView'"
                                />
                            </Field>
                            <span class="my-auto p-2">至</span>
                            <Field name="end_date" v-slot="{ meta, field }" v-model="coupon_data.end_date">
                                <input
                                    type="date"
                                    id="end_date"
                                    v-bind="field"
                                    class="form-control w-auto"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    min="2022-06-01"
                                    max="9999-12-31"
                                    :disabled="$route.name === 'CouponView'"
                                />
                            </Field>
                        </span>
                    </div>
                    <div>
                        <ErrorMessage name="start_date" class="date-invalid-feedback"/>
                    </div>
                    <div>
                        <ErrorMessage name="end_date" class="date-invalid-feedback"/>
                    </div>
                </div>
                <div class="mb-3" v-if="$route.name !== 'CouponView'">
                    <label for="image" class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>上傳圖片</label>
                    <input type="file" class="form-control" id="image" @change="file_selected" accept=".jpg, .jpeg, .png" ref="image_input" :disabled="$route.name === 'CouponView'">
                    <div id="image_help" class="form-text">檔案大小不可超過 5 MB，檔案格式：png、jpeg、jpg</div>
                </div>
                <div class="mb-3">
                    <img v-if="coupon_data.image && image == null" :src="coupon_data.image" style="width: 100%;"/> 
                    <img v-if="image" :src="image" style="width: 100%;"/> 
                </div>
                <div class="mb-3">
                    <label for="order" class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>排序</label>
                    <Field name="order" v-slot="{ meta, field }" v-model="coupon_data.order">
                        <input
                            v-bind="field"
                            class="form-control"
                            :class="{
                                'is-valid': meta.valid && meta.touched,
                                'is-invalid': !meta.valid && meta.touched,
                            }"
                            type="number"
                            :min="1"
                            :max="99999"
                            :disabled="$route.name === 'CouponView'"
                        />
                    </Field>
                    <ErrorMessage name="order" class="invalid-feedback"/>
                </div>
                <div class="mb-3">
                    <label class="form-label"><span class="required" v-if="$route.name !== 'CouponView'">*</span>好康優惠啟用狀態</label>
                    <div>
                        <div class="form-check form-check-inline">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="coupon_data.status">
                                <input
                                    id="status_radio_1"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="1"
                                    :checked="coupon_data.status === 1"
                                    :disabled="$route.name === 'CouponView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_1">是</label>
                        </div>
                        <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="status_options" id="status_radio_2" :value="0" v-model="coupon_data.status" :disabled="$route.name === 'CouponView'">
                            <Field name="status_options" v-slot="{ meta, field }" v-model="coupon_data.status">
                                <input
                                    id="status_radio_2"
                                    v-bind="field"
                                    class="form-check-input"
                                    :class="{
                                        'is-valid': meta.valid && meta.touched,
                                        'is-invalid': !meta.valid && meta.touched,
                                    }"
                                    type="radio"
                                    :value="0"
                                    :checked="coupon_data.status === 0"
                                    :disabled="$route.name === 'CouponView'"
                                />
                            </Field>
                            <label class="form-check-label" for="status_radio_2">否</label>
                        </div>
                    </div>
                    <ErrorMessage name="status_options" class="radio-invalid-feedback"/>
                </div>
                <div class="mb-3 mx-auto text-center">
                    <button type="submit" class="btn m-2 btn-primary" :disabled="button_disabled" v-if="$route.name !== 'CouponView'">送出</button>
                    <button type="button" class="btn m-2 btn-danger" v-on:click="go_back()">返回</button>
                </div>
                <div class="mb-3">
                </div>
            </div>
        </Form>

        <!-- Search Modal -->
        <div class="modal fade" id="staticBackdropSearch" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content" style="color: #000000;">
                    <div class="modal-header text-center bg-dark text-white">
                        <h5 class="modal-title w-100" id="staticBackdropLabel">
                            選擇店家資料
                        </h5>
                        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close" v-on:click="cancel()"></button>
                    </div>
                    <div class="modal-body">
                        <div class="col-12 row mb-3 mx-auto">
                            <div class="col-12 accordion mb-3" id="accordion_filter">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="heading_filter">
                                        <button class="accordion-button bg-dark text-white" type="button" data-bs-toggle="collapse" data-bs-target="#collapse_filter" aria-expanded="false" aria-controls="collapse_filter">
                                            搜尋條件
                                        </button>
                                    </h2>
                                    <div id="collapse_filter" class="accordion-collapse show" aria-labelledby="heading_filter" data-bs-parent="#accordion_filter">
                                        <div class="accordion-body col-12 row">
                                            <div class="mb-3 col-lg-4">
                                                <label for="store_code" class="form-label">店家編號</label>
                                                <input type="text" class="form-control" id="store_code" v-model="display_query_data.store_code">
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="store_name" class="form-label">店家名稱</label>
                                                <input type="text" class="form-control" id="store_name" v-model="display_query_data.store_name">
                                            </div>
                                            <div class="mb-3 col-lg-4">
                                                <label for="store_type" class="form-label">店家類型</label>
                                                <select id="store_type" class="form-select" v-model="display_query_data.store_type">
                                                    <option value="">全部</option>
                                                    <template v-for="item in store_type_option" :key="item">
                                                        <option :value="item.value">{{ item.text }}</option>
                                                    </template>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-12 row text-end mx-auto mb-3">
                                            <div>
                                                <button class="btn m-2 btn-primary" @click="search_store_data()">搜尋</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 row text-center mx-auto">
                                <div class="table-responsive col-12">
                                    <table class="table table-striped table-hover text-center align-middle">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class="col-2">店家編號</th>
                                                <th class="col-3">店家名稱</th>
                                                <th class="col-2">店家類型</th>
                                                <th class="col-2">店家啟用狀態</th>
                                                <th class="col-1"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="item in records" :key="item">
                                                <td>{{ item.store_code }}</td>
                                                <td>{{ item.store_name }}</td>
                                                <td>{{ item.store_type_name }}</td>
                                                <td>{{ item.store_status }}</td>
                                                <td>
                                                    <button type="button" class="btn btn-success" v-on:click="select(item)">選擇</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<<"
                                                @click.prevent="onChangePage(1)">
                                                <span aria-hidden="true">&laquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === 1}" v-bind:style="{'cursor': current_page === 1 ? null : 'pointer'}">
                                            <a class="page-link" aria-label="<"
                                                @click.prevent="onChangePage(current_page - 1)">
                                                <span aria-hidden="true">&lsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" v-for="page in links" :key="page" 
                                            :class="{'active': page.active, 'disabled': page.label === '...'}" v-bind:style="{'cursor': page.active ? null : page.label === '...' ? null : 'pointer'}">
                                            <a class="page-link" @click.prevent="onChangePage(page.label)">{{ page.label }}</a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">"
                                                @click.prevent="onChangePage(current_page + 1)">
                                                <span aria-hidden="true">&rsaquo;</span>
                                            </a>
                                        </li>
                                        <li class="page-item" :class="{'disabled': current_page === last_page}" v-bind:style="{'cursor': current_page === last_page ? null : 'pointer'}">
                                            <a class="page-link" aria-label=">>"
                                                @click.prevent="onChangePage(last_page)">
                                                <span aria-hidden="true">&raquo;</span>
                                            </a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                            <div class="col-12 row mx-auto mb-3">
                                <div class="input-group justify-content-center">
                                    <span class="text-vertical-center">前往第</span>
                                    <input type="number" class="form-control m-2" style="flex: 0 0 auto; width: auto;" id="current_page" v-model="current_page" :min="1" :max="9999999999">
                                    <span class="text-vertical-center">頁</span>
                                    <div>  
                                        <button class="btn m-2 btn-primary" @click.prevent="onChangePage(current_page)">前往</button>
                                    </div>
                                </div>
                            </div>
                            <div class="mb-3"></div>
                            <div class="col-12 row mx-auto mb-3" v-if="select_store_data.store_code !== '' && select_store_data.store_code !== undefined">
                                <div class="table-responsive col-12">
                                    <table class="table table-striped table-hover text-center align-middle">
                                        <thead class="table-dark">
                                            <tr>
                                                <th class="col-6">欄位名稱</th>
                                                <th class="col-6">資訊</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>店家編號</td>
                                                <td>{{ select_store_data.store_code }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家名稱</td>
                                                <td>{{ select_store_data.store_name }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家類型</td>
                                                <td>{{ select_store_data.store_type_name }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家地址</td>
                                                <td>{{ select_store_data.address }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家電話</td>
                                                <td>{{ select_store_data.phone }}</td>
                                            </tr>
                                            <tr>
                                                <td>網站連結</td>
                                                <td>{{ select_store_data.web_site }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家簡介</td>
                                                <td>{{ select_store_data.introduce }}</td>
                                            </tr>
                                            <tr>
                                                <td>店家啟用狀態</td>
                                                <td>{{ select_store_data.store_status }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" v-on:click="confirm()" :disabled="select_store_data.store_code === '' || select_store_data.store_code === undefined">確認</button>
                        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" v-on:click="cancel()">關閉</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { 
    get_coupon_detail,
    save_coupon_detail,
    get_coupon_store_list,
} from '../../api/coupon';
import { mapActions, mapGetters } from 'vuex';
import { get_store_type_option } from '../../api/data';
import { Field, Form, ErrorMessage } from 'vee-validate';
import { 
    coupon_rules,
} from '../../rules/store/coupon';
import { useRouter } from 'vue-router';
import Swal from 'sweetalert2';

export default {
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    data() {
        return {
            coupon_data: {
                store_code: "",
                store_name: "",
                store_type: "",
                phone: "",
                web_site: "",
                introduce: "",
                start_date: null,
                end_date: null,
                status: "",
                order: "",
                promotion_item: "",
                promotion_content: "",
                address: "",
            },
            store_type_option: [],
            image: null,
            button_disabled: false,
            upload_image: null,
            last_page: 1,
            current_page: 1,
            links: [{
                label: "1",
                active: true,
            }],
            records: [],
            display_query_data: {
                store_code: "",
                store_name: "",
                store_type: "",
            },
            query_data: {
                store_code: "",
                store_name: "",
                store_type: "",
            },
            select_store_data: {
                store_code: "",
                store_name: "",
                store_type: "",
                store_type_name: "",
                phone: "",
                web_site: "",
                introduce: "",
                address: "",
                store_status: "",
                status: "",
            },
            can_go_back: false,
        }
    },
    methods: {
        ...mapActions([
            'auth/UserLogout',
        ]),
        user_logout() {
            this['auth/UserLogout']().then(() => {
                this.$router.push({ name: 'Login' });
            }, error => {
                this.$router.push({ name: 'Login' });
            })
        },
        search() {
            get_coupon_detail({coupon_id: this.$route.params.id}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout()
                } else if (response.data.success === false && 
                    (
                        response.data.error_code === "1002" ||
                        response.data.error_code === "1003"
                    )) {
                    this.$router.push({ name: 'CouponList' });
                } else {
                    this.coupon_data = response.data.response_data;
                }
            })
        },
        file_selected(e) {
            if (e.target.files.length != 0) {
                if ((e.target.files.item(0).size / 1024 / 1024).toFixed(2) > 5) {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            cancelButton: 'm-2 btn btn-danger'
                        },
                        buttonsStyling: false
                    })

                    swalWithBootstrapButtons.fire({
                        title: '錯誤訊息',
                        text: '檔案大小超過 5 MB 限制！',
                        icon: 'error',
                        cancelButtonText: '關閉',
                        showCancelButton: true,
                        showConfirmButton: false,
                        showCloseButton: true,
                        allowOutsideClick: false,
                    });
                    this.$refs.image_input.value = null;
                    this.image = null;
                    this.upload_image = null;
                } else {
                    const file = e.target.files.item(0);
                    const reader = new FileReader();
                    reader.addEventListener('load', this.image_loaded);
                    reader.readAsDataURL(file);
                    this.upload_image = e.target.files[0];
                }
            } else {
                this.$refs.image_input.value = null;
                this.image = null;
                this.upload_image = null;
            }
        },
        image_loaded(e) {
            this.image = e.target.result;
        },
        save() {
            this.button_disabled = true;
            let submit_coupon_data = JSON.parse(JSON.stringify(this.coupon_data));
            submit_coupon_data.image = null;
            submit_coupon_data.validate_status = this.validate_status;
            let form_data = new FormData();
            form_data.append('coupon_data', JSON.stringify(submit_coupon_data));
            if (this.image !== null) {
                form_data.append('upload_image', this.upload_image, this.upload_image.name);
            }
            save_coupon_detail(form_data).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else {
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'm-2 btn btn-primary',
                        },
                        buttonsStyling: false
                    })
                    swalWithBootstrapButtons.fire({
                        title: '提示訊息',
                        text: response.data.message,
                        icon: 'success',
                        showCloseButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: '確認',
                    }).then((result) => {
                        if (result.isConfirmed || result.dismiss === "close") {
                            this.$router.push({ name: 'CouponList' });
                        }
                    })
                }
            }, error => {
                this.button_disabled = false;
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        cancelButton: 'm-2 btn btn-danger'
                    },
                    buttonsStyling: false
                })
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: error.response.data.message,
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            });
        },
        search_store_data(page = 1, page_change = false) {
            if (page_change === false) {
                this.query_data = JSON.parse(JSON.stringify(this.display_query_data));
            }
            get_coupon_store_list({page: page, query_data: this.query_data}).then(response => {
                if (response.data.success === false && response.data.check === false) {
                    this.user_logout();
                } else if (response.data.success === false && response.data.error_code === "1002") {
                    this.$router.push({ name: 'CouponList' });
                } else {
                    this.records = response.data.response_data.data;
                    this.links = response.data.response_data.links;
                    this.current_page = response.data.response_data.current_page;
                    this.last_page = response.data.response_data.last_page;
                }
            })
        },
        cancel() {
            this.select_store_data = {
                store_code: "",
                store_name: "",
                store_type: "",
                phone: "",
                web_site: "",
                introduce: "",
                address: "",
                store_status: "",
                status: "",
            }
            this.display_query_data = {
                store_code: "",
                store_name: "",
                store_type: "",
            }
            this.query_data = {
                store_code: "",
                store_name: "",
                store_type: "",
            }
        },
        select(item) {
            let store_data = JSON.parse(JSON.stringify(item));
            this.select_store_data.store_id = store_data.store_id;
            this.select_store_data.store_code = store_data.store_code;
            this.select_store_data.store_name = store_data.store_name;
            this.select_store_data.store_type = store_data.store_type;
            this.select_store_data.store_type_name = store_data.store_type_name;
            this.select_store_data.phone = store_data.phone;
            this.select_store_data.web_site = store_data.web_site;
            this.select_store_data.introduce = store_data.introduce;
            this.select_store_data.address = store_data.address;
            this.select_store_data.store_status = store_data.store_status;
            this.select_store_data.status = store_data.status;
        },
        confirm() {
            let store_data = JSON.parse(JSON.stringify(this.select_store_data));
            this.coupon_data.store_id = store_data.store_id;
            this.coupon_data.store_code = store_data.store_code;
            this.coupon_data.store_name = store_data.store_name;
            this.coupon_data.store_type = store_data.store_type;
            this.coupon_data.phone = store_data.phone;
            this.coupon_data.web_site = store_data.store_code;
            this.coupon_data.introduce = store_data.introduce;
            this.coupon_data.address = store_data.address;
            this.coupon_data.store_status = store_data.status;
            this.cancel();
        },
        on_invalid_submit() {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })

            swalWithBootstrapButtons.fire({
                title: '錯誤訊息',
                text: '尚有欄位未填寫或是填寫錯誤！',
                icon: 'error',
                cancelButtonText: '關閉',
                showCancelButton: true,
                showConfirmButton: false,
                showCloseButton: true,
                allowOutsideClick: false,
            });
        },
        go_back() {
            if (this.can_go_back === true) {
                this.$router.back();
            } else {
                this.$router.push({name: "CouponList"});
            }
        },
        onChangePage(page) {
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    cancelButton: 'm-2 btn btn-danger'
                },
                buttonsStyling: false
            })
            if (page > this.last_page || page <= 0) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，無指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else if (page == this.page) {
                swalWithBootstrapButtons.fire({
                    title: '錯誤訊息',
                    text: '頁碼錯誤，已於指定頁碼',
                    icon: 'error',
                    cancelButtonText: '關閉',
                    showCancelButton: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                    allowOutsideClick: false,
                })
            } else {
                this.search_store_data(page, true);
            }
        }
    },
    created() {
        window.scrollTo({top: 0, behavior: "smooth"});
        const router = useRouter();
        if (router.options.history.state.back !== null) {
            this.can_go_back = true;
        }
        if (this.$route.params.id !== undefined) {
            this.search();
        }
        get_store_type_option().then(response => {
            if (response.data.success === false && response.data.check === false) {
                this.user_logout();
            } else {
                this.store_type_option = response.data.response_data["TW"];
            }
        })
    },
    computed: {
        ...mapGetters({
            role_list: 'role_list'
        }),
        schema() {
            return coupon_rules;
        },
    }
};
</script>
