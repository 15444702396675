import * as yup from 'yup';

export const coupon_rules = yup.object({
    promotion_item: yup.string().max(100, "優惠商品名稱至多為100個字").required('優惠商品名稱尚未填寫'),
    promotion_content: yup.string().max(100, "優惠折扣至多為100個字").required('優惠折扣尚未填寫'),
    order: yup.number().typeError("必須為數字").min(1, "最小值為1").max(99999, "最大值為99999").required("排序尚未填寫"),
    status_options: yup.string().required("好康優惠啟用狀態尚未選擇"),
    start_date: yup.date().typeError("優惠期間起始日期尚未選擇")
        .when(
            'status_options',
            (status_options: any, schema: any) => {
                if (status_options !== "" && status_options !== undefined) {
                    return schema.required("優惠期間起始日期尚未選擇");
                }
                return schema;
            }),
    end_date: yup.date().typeError("優惠期間結束日期尚未選擇")
        .when(
            'start_date',
            (start_date: any, schema: any) => {
                if (start_date !== "" && start_date !== undefined && start_date !== null && start_date.toString() !== "Invalid Date") {
                    const dayAfter = new Date(new Date(start_date).getTime());
                    return schema.min(dayAfter, '結束日期不可早於起始日期').required("發佈期間結束日期尚未選擇");
                }
                return schema;
            }),
    store_code: yup.string().required('店家尚未選擇'),
})